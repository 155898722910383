<template>
  <div v-show="enable" id="canvas-zone" @dragover="dragover" @drop="drop">
    <canvas id="unity-canvas" ref="canvas" tabindex="1" />
  </div>
</template>

<script>
import { EventBus } from "../event";

export default {
  name: "WebglViewer",

  data() {
    const baseUrl = `${process.env.BASE_URL}webgl/`;
    const v = 'e0bff65f'
    return {
      vloaderUrl: `${baseUrl}viewer.loader.js`,
      config: {
        dataUrl: `${baseUrl}viewer.data?v=${v}`,
        frameworkUrl: `${baseUrl}viewer.framework.js?v=${v}`,
        codeUrl: `${baseUrl}viewer.wasm?v=${v}`,
        devicePixelRatio: Math.min(window.devicePixelRatio, 1.5),
      },
      unityInstance: null,
      loader: null,
      enable: false,
    };
  },
  mounted() {
    this.loader = document.createElement("SCRIPT");
    this.loader.setAttribute("src", this.vloaderUrl);
    this.loader.setAttribute("async", "");
    this.loader.onload = () => {
      vm.unityComponent = this;
      createUnityInstance(this.$refs.canvas, this.config).then((unity) => {
        this.unityInstance = unity;
        this.unityInstance.SendMessage("U3DViewInterface", "EnableSandboxMode");
      });
    };
    document.body.appendChild(this.loader);
    EventBus.$on("fileInput", this.load);
  },
  beforeDestroy() {
    document.body.removeChild(this.loader);
    if (this.unityInstance) {
      this.unityInstance.Quit();
      this.unityInstance = null;
      vm.unityComponent = null;
    }
    EventBus.$off("fileInput", this.load);
  },
  methods: {
    dragover(e) {
      e.preventDefault();
    },
    drop(e) {
      e.preventDefault();
      if (!e.dataTransfer.items.length) return;
      const file = e.dataTransfer.items[0].getAsFile();
      this.setFileUrl(file);
    },
    setFileUrl(file) {
      this.fileUrl = window.URL.createObjectURL(file);
      EventBus.$emit("fileInput", this.fileUrl);
    },
    delay(n) {
      return new Promise(function (resolve) {
        setTimeout(resolve, n * 1000);
      });
    },
    async load(url) {
      while (!this.unityInstance) {
        await this.delay(1);
      }
      this.unityInstance.SendMessage(
        "U3DViewInterface",
        "LoadAndShow3DAsset",
        url
      );
    },
    unityOnFinishAssetImport() {
      this.enable = true;
      EventBus.$emit("assetLoaded");
    },
    unityOnError(msg) {
      EventBus.$emit("error");
    },
  },
};

window.unityOnFinishAssetImport = function () {
  vm.unityComponent.unityOnFinishAssetImport();
};

window.unityOnError = function (msg) {
  vm.unityComponent.unityOnError(msg);
};
</script>

<style scoped>
#canvas-zone {
  display: block;
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
#unity-canvas {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: block;
  outline: none;
}
</style>
