<template>
  <div id="app" @drop.prevent @dragenter.prevent @dragover.prevent>
    <drop-zone v-if="enableDropZone" />
    <loading-overlay ref="loadingOverlay" v-show="enableOverlay" />
    <webgl-viewer />
  </div>
</template>

<script>
import Vue from "vue";
import WebglViewer from "./components/WebglViewer.vue";
import LoadingOverlay from "./components/LoadingOverlay.vue";
import DropZone from "./components/DropZone.vue";
import { EventBus } from "./event";

export default {
  name: "App",
  metaInfo: {
    title: "RenderThat Sandbox",
  },
  components: {
    WebglViewer,
    LoadingOverlay,
    DropZone,
  },
  data() {
    return {
      enableOverlay: true,
      enableDropZone: true,
      hasError: false,
    };
  },
  mounted() {
    EventBus.$on("fileInput", (url) => {
      this.enableOverlay = true;
      this.$refs.loadingOverlay.enableSpinner = true;
    });
    EventBus.$on("assetLoaded", () => {
      this.enableOverlay = false;
      this.enableDropZone = false;
      this.$refs.loadingOverlay.enableSpinner = false;
    });
    EventBus.$on("error", () => {
      this.showErrorToast();
      this.enableDropZone = true;
      this.$refs.loadingOverlay.enableSpinner = false;
    });
  },
  methods: {
    showErrorToast() {
      if (!this.hasError) {
        this.hasError = true;
        Vue.$toast.open({
          message: "Ooops! Something went wrong.",
          type: "error",
          position: "top",
          duration: 5000,
          onDismiss: () => {
            this.hasError = false;
          },
        });
      }
    },
  },
};
</script>

<style>
@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
   url(./fonts/Roboto-Regular.ttf) format("truetype");
}
html,
body {
  font-family: Roboto;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#app {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #141414;
}
</style>
