<template>
  <div id="drop-zone" @dragover="dragover" @drop="drop">
    <input
      type="file"
      id="file-input"
      @change="handleFileUpload"
      accept=".glb"
      hidden
    />
    <label for="file-input" id="droptext">Drag & drop a .glb file here.</label>
  </div>
</template>

<script>
import { EventBus } from "../event";

export default {
  name: "DropZone",
  data() {
    return {
      fileUrl: "",
    };
  },
  methods: {
    handleFileUpload(e) {
      if (!e.target.files.length) return;
      const file = e.target.files[0];
      this.setFileUrl(file);
    },
    dragover(e) {
      e.preventDefault();
    },
    drop(e) {
      e.preventDefault();
      if (!e.dataTransfer.items.length) return;
      const file = e.dataTransfer.items[0].getAsFile();
      this.setFileUrl(file);
    },
    setFileUrl(file) {
      this.fileUrl = window.URL.createObjectURL(file);
      EventBus.$emit("fileInput", this.fileUrl);
    },
  },
};
</script>

<style scoped>
#drop-zone {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
#droptext {
  position: absolute;
  text-align: center;
  height: 50px;
  width: 100%;
  bottom: 100px;
  color: white;
  font-size: 19px;
  cursor: pointer;
}
</style>