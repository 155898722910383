<template>
  <div class="container">
    <img src="@/assets/Logo_Colour_PNG_White.png" alt="RenderThat" id="logo" />
    <spinner v-if="enableSpinner" id="spinner" />
  </div>
</template>

<script>
import Spinner from "./Spinner.vue";

export default {
  name: "LoadingOverlay",
  components: {
    Spinner,
  },
  data() {
    return {
      enableSpinner: false,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
#logo {
  width: 350px;
  height: auto;
}
#spinner {
  position: absolute;
}
</style>