<template>
  <div class="spinner"></div>
</template>

<script>
export default {
  name: "Spinner",
};
</script>

<style scoped>
@keyframes spinner {
  to {transform: rotate(360deg);}
}
 
.spinner:before {
  content: '';
  display: block;
  top: 50%;
  left: 50%;
  width: 350px;
  height: 350px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border-top: 4px solid #00C65E;
  border-right: 4px solid transparent;
  animation: spinner .6s linear infinite;
}
</style>